import React from 'react'
import T from 'prop-types'
import InputMask from 'react-text-mask'
import {compose, withState, defaultProps, withHandlers, setPropTypes} from 'recompose'
import styles from './TextInput.module.css'
import cx from 'classnames'

export const TextInput = ({value, noBlurError, isErrShown, error, mask, wideWidth, required, name, placeholder, label, onChange, onBlur, onIsErrShownSet, ...other}) => {
  const Component = mask ? InputMask : 'input'
  const props = mask ? {...other, mask} : other

  return (
    <label className={cx(styles.root, {[styles.wideWidth]: wideWidth})}>
      {label ? <span className={styles.label} dangerouslySetInnerHTML={{__html: label}}/> : null}
      <Component
        {...props}
        name={name}
        value={value}
        required={required}
        className={styles.input}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && (noBlurError ? true : isErrShown) ? <span className={styles.error}>{error}</span> : null}
    </label>
  )
}

export const enhance = compose(
  withState('isErrShown', 'onIsErrShownSet', false),
  withHandlers({
    onChange: props => ev => {
      props.onChange(ev.target.value)
      if (props.error) props.onIsErrShownSet(false)
    },
    onBlur: props => ev => {
      if (props.error) props.onIsErrShownSet(true)
      if (props.onBlur) props.onBlur(ev)
    },
  }),
  defaultProps({type: 'text'}),
  setPropTypes({
    type: T.string.isRequired,
    value: T.string.isRequired,
    name: T.string.isRequired,
    required: T.bool,
    placeholder: T.string,
    label: T.string,
    error: T.any,
    noBlurError: T.bool,
    isErrShown: T.bool,
    onChange: T.func.isRequired,
    onBlur: T.func.isRequired,
    onIsErrShownSet: T.func.isRequired,
  }),
)

export default enhance(TextInput)
