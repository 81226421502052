import React, {useState, Component} from 'react'
import cx from 'classnames';
import T from 'prop-types'
import Select from './Select';
import styles from './RadioSlider.module.css'

export const RadioItem = ({item, activeValue, name, scrollX, activeSelectValue, onChange}) => {
  const [selectValue, setSelectValue] = useState(item.gsm[0])

  return (
    <article className={styles.article} key={item.title} style={{backgroundColor: item.color}}>
      <label className={cx(styles.wrapper, {[styles.selected]: item.title === activeValue})}>
        <input
          name={name}
          type="radio"
          value={item.title}
          className={styles.radio}
          checked={activeValue === item.title}
          onChange={() => onChange([item.title, selectValue])}
        />
        <span className={styles.label}>{item.title}</span>
        <p className={styles.description}>{item.description}</p>
      </label>

      <Select
        scrollX={scrollX}
        options={item.gsm}
        value={selectValue || item.gsm[0]}
        onChange={val => {
          setSelectValue(val)
          if (activeValue === item.title) onChange([item.title, val])
        }}
        />
    </article>
  )
}

export class RadioSlider extends Component {
  static defaultProps = {required: false}

  static propTypes = {
    items: T.arrayOf(T.shape({
      title: T.string.isRequired,
      color: T.string.isRequired,
      description: T.string.isRequired,
      gsm: T.array.isRequired,
    }).isRequired).isRequired,
    required: T.bool.isRequired,
    name: T.string.isRequired,
    value: T.array.isRequired,
    onChange: T.func.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.$container = React.createRef()
  }

  state = {
    scrollX: 0,
    isScrollLockActive: false,
  }

  componentDidMount() {
    this.setState({width: (this.$container.current && this.$container.current.scrollWidth) || 0})
  }

  updateScroll = () => {
    if (this.$container.current) {
      this.$container.current.scrollLeft = this.state.scrollX
    }
  }

  onRangeChange = ev => {
    if (this.$container.current) {
      const containerScrollWidth = (this.$container.current && this.$container.current.scrollWidth) || 0;
      const containerOffsetWidth = (this.$container.current && this.$container.current.offsetWidth) || 0;
      const rangeMax = containerScrollWidth - containerOffsetWidth;
      this.setState({scrollX: Number(Math.max(0, Math.min(rangeMax, ev.target.value)))});
    }
  }

  onScroll = ev => this.setState({scrollX: this.$container.current.scrollLeft})

  onPrev = () => {
    const $article = document.querySelector(`.${styles.article}`);

    if ($article && this.$container.current) {
      const width = $article.offsetWidth;
      const margin = 5; // I'm too lazy to calculate it
      const containerScrollWidth = (this.$container.current && this.$container.current.scrollWidth) || 0;
      const containerOffsetWidth = (this.$container.current && this.$container.current.offsetWidth) || 0;
      const rangeMax = containerScrollWidth - containerOffsetWidth;
      this.setState({scrollX: Number(Math.max(0, Math.min(rangeMax, this.$container.current.scrollLeft - ((width + margin * 2)))))});
    }
  }

  onNext = () => {
    const $article = document.querySelector(`.${styles.article}`);

    if ($article && this.$container.current) {
      const width = $article.offsetWidth;
      const margin = 5; // I'm too lazy to calculate it
      const containerScrollWidth = (this.$container.current && this.$container.current.scrollWidth) || 0;
      const containerOffsetWidth = (this.$container.current && this.$container.current.offsetWidth) || 0;
      const rangeMax = containerScrollWidth - containerOffsetWidth;
      this.setState({scrollX: Number(Math.max(0, Math.min(rangeMax, this.$container.current.scrollLeft + ((width + margin * 2)))))});
    }
  }

  render() {
    const {scrollX} = this.state
    const {items, name, value} = this.props
    const containerScrollWidth = (this.$container.current && this.$container.current.scrollWidth) || 0
    const containerOffsetWidth = (this.$container.current && this.$container.current.offsetWidth) || 0
    const rangeMax = containerScrollWidth - containerOffsetWidth
    const [activeValue, activeSelectValue] = value
    this.updateScroll()

    return (
      <section className={styles.root} id="radio-slider">
        <section className={styles.wrap} ref={this.$container} onScroll={this.onScroll}>
          <section className={styles.container}>
            {items.map(item => (
              <RadioItem
                key={item.title}
                item={item}
                name={name}
                scrollX={scrollX}
                activeValue={activeValue}
                activeSelectValue={activeSelectValue}
                onChange={this.props.onChange}
                />
            ))}
          </section>
        </section>

        <section className={styles.controls}>
          <button type="button" className={cx(styles.arrow, styles.left)} onClick={this.onPrev}>
            <svg viewBox="0 0 11 18">
              <path d="M10 17L2 8.53 10 1" strokeWidth="2" fill="none" strokeLinecap="round"/>
            </svg>
          </button>
          <div className={styles.range}>
            <div className={styles.pseudorange}>
              <div
                className={styles.pseudothumb}
                style={{
                  width: `${containerOffsetWidth / containerScrollWidth * 100}%`,
                  left: `${(scrollX / rangeMax) * (100 - (containerOffsetWidth / containerScrollWidth * 100))}%`,
                }}
                />
            </div>
            <input
              min="0"
              type="range"
              max={rangeMax}
              value={scrollX}
              className={styles.rangeinput}
              onChange={this.onRangeChange}
            />
          </div>
          <button type="button" className={cx(styles.arrow, styles.right)} onClick={this.onNext}>
            <svg viewBox="0 0 11 18">
              <path d="M1 1l8 8.47L1 17" strokeWidth="2" fill="none" strokeLinecap="round"/>
            </svg>
          </button>
        </section>
      </section>
    );
  }
}

export default RadioSlider
