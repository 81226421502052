import React from 'react'
import T from 'prop-types'
import styles from './Grid.module.css'

export const Grid = ({children}) => (
  <section className={styles.root}>
    {children}
  </section>
)

Grid.propTypes = {children: T.node}

export default Grid
