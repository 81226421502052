import React from 'react'
import cx from 'classnames'
import T from 'prop-types'
import {compose, setPropTypes, defaultProps} from 'recompose'
import styles from './Button.module.css'

export const Button = ({type, loading, disabled, className, children, ...props}) => (
  <div className={styles.container}>
    <button {...props} disabled={disabled} className={cx(styles.root, className, {[styles.loading]: loading})} type={type}>
      {children}
    </button>
  </div>
)

export const enhance = compose(
  defaultProps({
    type: 'submit',
    disabled: false,
    loading: false,
  }),
  setPropTypes({
    className: T.string,
    disabled: T.bool.isRequired,
    loading: T.bool.isRequired,
    type: T.oneOf(['button', 'submit']).isRequired,
    children: T.oneOfType([T.string.isRequired, T.node.isRequired]).isRequired,
  }),
)

export default enhance(Button)
