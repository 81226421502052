import React from 'react';
import T from 'prop-types';
import styles from './Fieldset.module.css';

export const Fieldset = ({children, ...props}) => (
  <fieldset className={styles.root} {...props}>
    {children}
  </fieldset>
);

Fieldset.propTypes = {
  children: T.node,
};

export default Fieldset;
