import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import styles from './TextArea.module.css'

export const TextArea = ({label, className, ...props}) => (
  <label className={cx(styles.root, className)}>
    <span className={styles.label}>{label}</span>
    <textarea className={cx(styles.area, className)} {...props}/>
  </label>
)

TextArea.propTypes = {
  className: T.string,
  label: T.string.isRequired,
}

export default TextArea
