import React from 'react';
import cx from 'classnames';
import T from 'prop-types';
import {compose, withState, withHandlers, setPropTypes} from 'recompose';
import styles from './Tabs.module.css';

export const Tabs = ({activeIndex, tabs, blocks, onChange}) => (
  <section className={styles.root}>
    <nav className={styles.nav}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          type="button"
          className={cx(styles.button, {[styles.active]: index === activeIndex})}
          onClick={() => onChange(index)}
          >
          {tab}
        </button>
      ))}
    </nav>

    {blocks[activeIndex]}
  </section>
);

export const enhance = compose(
  withState('activeIndex', 'setActiveIndex', 0),
  withHandlers({
    onChange: props => index => {
      props.setActiveIndex(index)
      if (props.onTabChange) props.onTabChange(index)
    },
  }),
  setPropTypes({
    activeIndex: T.number.isRequired,
    tabs: T.arrayOf(T.string.isRequired).isRequired,
    blocks: T.arrayOf(T.node.isRequired).isRequired,
    onChange: T.func.isRequired,
    onTabChange: T.func,
  }),
);

export default enhance(Tabs);
