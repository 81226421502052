import * as Steps from './Steps'

export default {
  steps: {
    posters: [
      ['format', Steps.Format],
      ['edition', Steps.Edition],
      ['paper', Steps.Paper],
      ['colors', Steps.Colors],
      ['extra', Steps.Extra],
    ],
    postcards: [
      ['format', Steps.Format],
      ['edition', Steps.Edition],
      ['paper', Steps.Paper],
      ['colors', Steps.Colors],
      ['extra', Steps.Extra],
    ],
    multipages: [
      ['format', Steps.Format],
      ['edition', Steps.Edition],
      ['pages', Steps.Pages],
      ['brace', Steps.Brace],
      ['paper', Steps.Paper],
      ['colors', Steps.Colors],
      ['extra', Steps.Extra],
    ],
  }
}
