import React from 'react'
import cx from 'classnames'
import T from 'prop-types'
import styles from './Container.module.css'

export const Container = ({className, children}) => (
  <section className={cx(styles.root, className)}>
    {children}
  </section>
)

Container.propTypes = {
  children: T.node,
  className: T.string,
}

export default Container
