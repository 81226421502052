import React from 'react'
import T from 'prop-types'
import {uniq, flatten} from 'ramda'
import {connect} from 'react-redux'
import {compose, setPropTypes, withHandlers} from 'recompose'
import * as C from '../../../../_'
import * as Q from 'queries'
import * as $$ from 'ducks'
import * as U from 'utils'
import styles from './Summary.module.css'

function pluralize(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

export const Summary = ({data, type, caption, onSubmit, onBack, ...props}) => {
  const [papers] = Q.usePapers()
  const colors = Q.useColors()
  const [price, priceForOne] = U.calculate(type, data, papers, colors)

  return (
    <>
      <C.Container>
        <button type="button" className={styles.back} onClick={onBack}>← Назад к расчетам</button>

        <header className={styles.header}>
          <div className={styles.block}>
            <h2>{`${price}₽`}</h2>
            <small>общая стоимость</small>
          </div>

          <div className={styles.block}>
            <h2>{`${priceForOne}₽`}</h2>
            <small>стоимость одного экземпляра</small>
          </div>
        </header>

        <ul className={styles.list}>
          <li><span>{`${data.format[0]} x ${data.format[1]} мм`}</span></li>
          <li><span>{`${data.edition} ${pluralize(data.edition, 'копия', 'копии', 'копий')}`}</span></li>
          {data.pages ? <li><span>{`${data.pages} ${pluralize(data.pages, 'полоса', 'полосы', 'полос')}`}</span></li> : null}
          {data.brace ? <li><span>{`${data.brace}`}</span></li> : null}
          <li><span>{`${data.paper.join(' ')} GSM`}</span></li>
          <li><span>{`${uniq(flatten(data.colors).map(color => colors.find(c => c.value === color).label)).join(', ')}`}</span></li>
          {data.isColorProofs ? <li><span>Цветопробы включены</span></li> : null}
          <li><span>{`${data.projectComment}`}</span></li>
        </ul>
      </C.Container>

      <C.Footer>
        <C.Caption __html={props.summary || caption || ''}/>
        <div>
          <span/>
          <C.Button onClick={onSubmit}>Оформить заказ</C.Button>
        </div>
      </C.Footer>
    </>
  )
}

export const mapStateToProps = state => ({
  data: $$.selectors.calculator.data(state),
  type: $$.selectors.calculator.type(state),
})

export const enhance = compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: props => () => props.onSubmit(),
  }),
  setPropTypes({
    data: T.object.isRequired,
    type: T.string.isRequired,
    onSubmit: T.func.isRequired,
    onBack: T.func.isRequired,
    caption: T.string,
  }),
)

export default enhance(Summary)
