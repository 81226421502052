import React from "react"
import T from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { connect } from "react-redux"
import * as $$ from "ducks"
import { uniq, flatten } from "ramda"
import * as Q from "queries"
import * as C from "../../_"
import * as utils from "utils"
import styles from "./Success.module.css"

const noop = () => {}

const types = {
  posters: "Постеры",
  postcards: "Открытки",
  multipages: "Многостраничники",
}

const delivery = {
  courier: "Курьерская доставка",
  pickup: "Самовывоз: Москва, 4-й Сыромятнический пер., 1/8 строение 6",
}

const getTextareaValue = (
  data,
  type,
  calcs,
  papers,
  colors
) => `Новый запрос на печать на сайте eshprint.com

${calcs[0]}₽ общая стоимость
${calcs[1]}₽ стоимость одного экземпляра
Тип: ${types[type]}
Формат: ${data.format[0]}x${data.format[1]}мм
Тираж: ${data.edition} копий
${data.pages ? `Количество полос: ${data.pages}` : ""}
${data.brace ? `Скрепление: ${data.brace}` : ""}
Бумага: ${data.paper[0]} ${data.paper[1]} GSM
Цвета: ${uniq(flatten(data.colors)).join(", ")}
Цветопробы: ${data.isColorProofs ? "Да" : "Нет"}
${data.projectComment ? `Комментарий: «${data.projectComment}»` : ""}
Название проекта: ${data.title}
Файлы стороны 1: ${Object.keys(data.files[0])
  .map(key => `${key}: ${data.files[0][key]}`)
  .join(", ")}
${
  data.files[1] && Object.keys(data.files[1]).length !== 0
    ? `Файлы стороны 2: ${Object.keys(data.files[1])
        .map(key => `${key}: ${data.files[1][key]}`)
        .join(", ")}`
    : ""
}
${data.files[2] ? `Файл всех цветов: ${data.files[2]}` : ""}
Доставка: ${delivery[data.delivery]}
Фамилия Имя: ${data.contacts.name}
Телефон: ${data.contacts.tel}
Email: ${data.contacts.email}
${data.contacts.city ? `Город: ${data.contacts.city}` : ""}
${data.contacts.address ? `Адрес: ${data.contacts.address}` : ""}
${data.contacts.flat ? `Подъезд, этаж, квартира: ${data.contacts.flat}` : ""}
${data.contacts.index ? `Индекс: ${data.contacts.index}` : ""}
${data.extra ? `Комментарий: «${data.extra}»` : ""}
`

export const Success = ({ type, data, error, onClose }) => {
  const file = Q.useSuccessImage()
  const [papers] = Q.usePapers()
  const colors = Q.useColors()
  const calcs = utils.calculate(type, data, papers, colors)

  return error ? (
    <section className={styles.error}>
      <section className={styles.wrapper}>
        <h1 className={styles.title}>Это какая-то ошибка</h1>
        <C.TextArea
          id="copy-textarea"
          value={getTextareaValue(data, type, calcs, papers, colors)}
          label="Мы не смогли отправить письмо заказа автоматически. Пожалуйста, скопируйте детали вашего заказа:"
          className={styles.textarea}
          onChange={noop}
        />
        <button
          type="button"
          className={styles.copy}
          onClick={e => {
            const $t = window.document.getElementById("copy-textarea")
            $t.select()
            window.document.execCommand("copy")
            e.target.focus()
          }}
        >
          скопировать в буфер обмена
        </button>
        <C.Label
          className={styles.lbl}
          dangerouslySetInnerHTML={{
            __html: `и отправьте его нам на почту <a href="mailto:inbox@eshprint.com">inbox@eshprint.com</a> лично`,
          }}
        />
        <C.Caption
          __html={`Мы не уверены, что знаем, почему так получилось, но мы знаем, что хотим выполнить ваш заказ. Вы можете также сообщить нам об ошибке по почте, чтобы таких ошибок впредь было меньше`}
        />
      </section>

      <section className={styles.image}>
        <Img
          objectFit="cover"
          objectPosition="50% 50%"
          alt="success"
          key="success"
          className={styles.img}
          fluid={file.childImageSharp.fluid}
        />
      </section>
    </section>
  ) : (
    <section className={styles.root}>
      <h1 className={styles.title}>
        Спасибо!
        <br />
        Ваш заказ принят в обработку, мы с вами свяжемся
      </h1>

      <div className={styles.icon}>
        <Img
          objectFit="cover"
          objectPosition="50% 50%"
          alt="success"
          key="success"
          fluid={file.childImageSharp.fluid}
        />
      </div>

      <footer className={styles.footer}>
        <Link to="/" className={styles.link} onClick={onClose}>
          Вернуться на главную
        </Link>
      </footer>
    </section>
  )
}

export const mapStateToProps = state => ({
  error: $$.selectors.calculator.error(state),
  type: $$.selectors.calculator.type(state),
  data: $$.selectors.calculator.data(state),
})

export const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch($$.actions.calculator.reset()),
})

Success.propTypes = {
  data: T.object,
  error: T.object,
  type: T.string.isRequired,
  onClose: T.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Success)
