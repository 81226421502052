import React from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, withHandlers, setPropTypes} from 'recompose'
import * as Q from 'queries'
import * as U from 'utils'
import * as C from '../../../_'
import * as $$ from 'ducks'


export const Colors = ({value, type, onSubmit, onFirstSideChange, onSecondSideChange, onReset, ...props}) => {
  const colors = Q.useColors()
  const error = U.validate.colors.validate(value)

  return (
    <C.Form onSubmit={error ? null : onSubmit}>
      <C.Label colorsLabel={true}>Выберите цвета для печати</C.Label>
      {type === 'posters' || type === 'postcards' ? (
        <div className="wrapper">
          <C.Container>
            <C.Label>Сторона 1</C.Label>
            <C.ColoredCheckboxes
              options={colors}
              selected={value[0]}
              name="first-side-colors"
              onChange={onFirstSideChange}
              />
          </C.Container>

            <C.Container>
              <C.Label faded={(value[1] || []).length === 0}>Сторона 2</C.Label>
              <C.ColoredCheckboxes
                options={colors}
                selected={value[1]}
                name="second-side-colors"
                onChange={onSecondSideChange}
                />
            </C.Container>
        </div>
      ) : (
        <C.Container>
          <C.ColoredCheckboxes
            options={colors}
            selected={value[0]}
            name="first-side-colors"
            onChange={onFirstSideChange}
            />
        </C.Container>
      )}

      <C.Footer>
        <C.Caption __html={props.colors || ''}/>
        <div>
          <C.Reset onClick={onReset}>Заново</C.Reset>
          <C.Button disabled={Boolean(error)}>Далее</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  value: $$.selectors.calculator.data(state).colors,
  type: $$.selectors.calculator.type(state),
})

export const mapDispatchToProps = dispatch => ({
  onChange: value => dispatch($$.actions.calculator.setData('colors', value)),
  onReset: () => dispatch($$.actions.calculator.reset()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onFirstSideChange: props => ev => {
      if (props.value[0].includes(ev.target.value)) {
        props.onChange([props.value[0].filter(v => v !== ev.target.value), props.value[1]])
      } else {
        props.onChange([[...props.value[0], ev.target.value], props.value[1]])
      }
    },
    onSecondSideChange: props => ev => {
      if (props.value[1].includes(ev.target.value)) {
        props.onChange([props.value[0], props.value[1].filter(v => v !== ev.target.value)])
      } else {
        props.onChange([props.value[0], [...props.value[1], ev.target.value]])
      }
    },
  }),
  setPropTypes({
    type: T.oneOf(['posters', 'postcards', 'multipages']).isRequired,
    value: T.array.isRequired,
    onSubmit: T.func.isRequired,
    onFirstSideChange: T.func.isRequired,
    onSecondSideChange: T.func.isRequired,
    onReset: T.func.isRequired,
  }),
)

export default enhance(Colors)
