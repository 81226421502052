import React from 'react'
import cx from 'classnames'
import {connect} from 'react-redux'
import * as $$ from 'ducks'
import T from 'prop-types'
import * as C from '../../../../_'
import styles from './File.module.css'

const icons = {
  upload: (
    <svg viewBox="0 0 20 20" width="20" height="20">
      <path d="M16.007 9.121L10 3.114 3.993 9.12l1.105 1.105 4.12-4.121V20h1.563V6.105l4.121 4.121 1.105-1.105zM20 1.562H0V0h20v1.563z"/>
    </svg>
  ),
  abort: (
    <svg viewBox="0 0 14 14" width="14" height="14">
      <g fillRule="evenodd">
        <path d="M1.555.565l11.88 11.88-.99.99L.565 1.555z"/>
        <path d="M.565 12.445L12.445.565l.99.99-11.88 11.88z"/>
      </g>
    </svg>
  ),
  ok: (
    <svg width="15" height="8" viewBox="0 0 15 8">
      <path d="M1 3l5 5 8-8" fill="none"/>
    </svg>
  ),
}

export const File = ({id, projectTitle, color = {}, status, folderName, caption, value, autoFocus, onChange, onSet, onRemove, onUpload}) => {
  const onAbort = ev => {
    onRemove(id)
    ev.preventDefault()
  }

  return (
    <div className={styles.root}>
      {caption ? (
        <C.Label>{caption}</C.Label>
      ) : (
        <C.Label>Цвет <span style={{color: color.color}}>{color.label}</span></C.Label>
      )}
      <div className={styles.wrapper}>
        <div className={styles.inputContainer}>
          <C.TextInput
            required
            name="files"
            noBlurError
            className={styles.input}
            error={status && status.error}
            disabled={status && status.name}
            value={status ? status.name : value || ''}
            autoFocus={autoFocus}
            onChange={status ? () => {} : onChange}
          />

          {status && (status.progress && status.name) ? (
            <div className={cx(styles.progress, {[styles.loaded]: status.progress === 100})} style={{width: `${status.progress}%`}}>
              {status.name}
            </div>
          ) : null}
        </div>

        <label className={styles.upload} onClick={status ? onAbort : null}>
          {status ? null : (
            <input
              type="file"
              name="file"
              onChange={ev => onUpload(id, ev.target.files[0], `${folderName}_${projectTitle}`, color.value)}
            />
          )}
          <span className={cx(styles.icon, {[styles.ok]: status && status.progress === 100})}>
            {status ? icons.abort : icons.upload}
            {icons.ok}
          </span>
        </label>
      </div>
    </div>
  )
}

export const mapStateToProps = (state, ownProps) => ({
  status: $$.selectors.files.file(state, ownProps.id),
  projectTitle: $$.selectors.calculator.data(state).title,
})

export const mapDispatchToProps = ({
  onSet: $$.actions.files.set,
  onRemove: $$.actions.files.remove,
  onUpload: $$.actions.files.upload,
})

File.propTypes = {
  id: T.string.isRequired,
  projectTitle: T.string.isRequired,
  status: T.shape({
    name: T.string.isRequired,
    progress: T.number.isRequired,
    error: T.string,
  }),
  autoFocus: T.bool,
  caption: T.string,
  folderName: T.string.isRequired,
  color: T.shape({
    label: T.string.isRequired,
    color: T.string.isRequired,
  }),
  value: T.string.isRequired,
  onChange: T.func.isRequired,
  onSet: T.func.isRequired,
  onRemove: T.func.isRequired,
  onUpload: T.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(File)
