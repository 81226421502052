import React from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, withHandlers, setPropTypes} from 'recompose'
import * as $$ from 'ducks'
import * as C from '../../_'
import * as Steps from './Steps'
import data from './data'

export const Parameters = ({type, step, onBack, onSetStage, onSubmit, ...props}) => {
  const Step = (data.steps[type].find(([key]) => key === step) || [])[1] || Steps.Summary

  return (
    <C.Root>
      {step === 'summary' ? null : (
        <C.Navigation
          active={step}
          elements={data.steps[type].map(([key]) => key)}
          onChange={key => key ? onSubmit(key) : onSetStage('initial')}
          />
      )}
      <Step {...props} onSubmit={onSubmit} onBack={onBack}/>
    </C.Root>
  )
}

export const mapStateToProps = state => ({
  type: $$.selectors.calculator.type(state),
  step: $$.selectors.calculator.step(state),
})

export const mapDispatchToProps = ({
  onSubmit: $$.actions.calculator.setStep,
  onSetStage: $$.actions.calculator.setStage,
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onBack: props => () => {
      props.onSubmit('extra')
    },
    onSubmit: props => step => {
      if (step) {
        props.onSubmit(step)
      } else {
        if (props.step === 'summary') {
          props.onSubmit('title')
          props.onSetStage('checkout')
        } else {
          const steps = data.steps[props.type].map(([key]) => key)
          const currentIndex = steps.indexOf(props.step)
          props.onSubmit(steps[currentIndex + 1] || 'summary')
        }
      }
    },
  }),
  setPropTypes({
    type: T.oneOf(['posters', 'postcards', 'multipages']).isRequired,
    step: T.oneOf(['format', 'edition', 'pages', 'brace', 'paper', 'colors', 'extra', 'summary']).isRequired,
  }),
)

export default enhance(Parameters)
