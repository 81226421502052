import React from 'react';
import T from 'prop-types';
import styles from './RadioGroup.module.css';

export const RadioGroup = ({children, label}) => (
  <div className={styles.root}>
    {label ? <h4 className={styles.label}>{label}</h4> : null}
    <div className={styles.container}>
      {children}
    </div>
  </div>
);

RadioGroup.propTypes = {
  children: T.node,
  label: T.string,
};

export default RadioGroup;
