import React from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, setPropTypes} from 'recompose'
import * as C from '../../../_'
import * as Q from 'queries'
import * as $$ from 'ducks'

export const Extra = ({value, isPending, onSubmit, onChange, onReset, ...props}) => {
  const isValid = true
  const [papers] = Q.usePapers()
  const colors = Q.useColors()

  return (
    <C.Form onSubmit={isValid && !isPending ? () => onSubmit(undefined, papers, colors) : null}>
      <C.Container>
        <C.Label style={{maxWidth: 600}}>
          Вскоре после того как вы нажмете кнопку «Отправить заказ», мы ответим вам на почту для подтверждения заказа, уточнения деталей и стоимости
        </C.Label>
        <C.TextArea
          autoFocus
          value={value}
          label="Если у вас есть важный комментарий, напишите"
          placeholder="Важный комметарий"
          onChange={e => onChange(e.target.value)}
        />
      </C.Container>

      <C.Footer>
        <C.Caption __html={props.checkoutExtra || ''}/>
        <div>
          <C.Reset onClick={onReset}>К расчётам</C.Reset>
          <C.Button loading={isPending} disabled={!isValid}>Отправить</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  value: $$.selectors.calculator.data(state).extra,
  isPending: $$.selectors.calculator.isPending(state),
})

export const mapDispatchToProps = dispatch => ({
  onChange: value => dispatch($$.actions.calculator.setData('extra', value)),
  onReset: () => dispatch($$.actions.calculator.resetCheckout()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    isPending: T.bool.isRequired,
    value: T.string.isRequired,
    onChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
  }),
)

export default enhance(Extra)
