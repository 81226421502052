import React from 'react';
import T from 'prop-types';
import styles from './Root.module.css';

export const Root = ({children}) => (
  <section className={styles.root}>
    {children}
  </section>
);

Root.propTypes = {children: T.node};

export default Root;
