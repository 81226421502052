import React from 'react'
import T from 'prop-types'
import styles from './Footer.module.css'

export const Footer = ({children, ...props}) => (
  <footer className={styles.root} {...props}>
    {children}
  </footer>
)

Footer.propTypes = {children: T.node}

export default Footer
