import CalculatorPage from './CalculatorPage'
import {graphql} from 'gatsby'

export default CalculatorPage

export const pageQuery = graphql`
  query CalculatorPageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "calculator-page"}}) {
      frontmatter {
        seo {
          title
          description
        }
        brace
        checkoutExtra
        checkoutTitle
        colors
        contacts
        delivery
        edition
        files
        orderExtra
        pages
        paper
        summary
        initial
        format
      }
    }
  }
`
