import React from 'react'
import T from 'prop-types'
import styles from './Checkbox.module.css'

export const Checkbox = ({label, ...props}) => (
  <label className={styles.root}>
    <input className={styles.checkbox} type="checkbox" {...props}/>
    <span className={styles.label}>
      <span className={styles.arrow}>
        <svg viewBox="0 0 15 12">
          <path d="M2 4.5L6.304 10 13 2" strokeWidth="2" fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </span>
      {label}
    </span>
  </label>
)

Checkbox.propTypes = {
  label: T.string.isRequired,
}

export default Checkbox
