import React from 'react';
import T from 'prop-types';
import {compose, withHandlers, setPropTypes} from 'recompose';
import styles from './Radio.module.css';

export const Radio = ({name, checked, label, value, autoFocus, onChange}) => (
  <label className={styles.root}>
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      autoFocus={autoFocus}
      className={styles.radio}
      onChange={onChange}
      />
    <span className={styles.label}>{label}</span>
  </label>
);

export const enhance = compose(
  withHandlers({
    onChange: props => () => props.onChange(props.value),
  }),
  setPropTypes({
    checked: T.bool,
    autoFocus: T.bool,
    name: T.string.isRequired,
    label: T.string.isRequired,
    value: T.string.isRequired,
    onChange: T.func.isRequired,
  }),
);

export default enhance(Radio);
