import React from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, setPropTypes} from 'recompose'
import * as C from '../../../_'
import * as U from 'utils'
import * as $$ from 'ducks'

const radios = {
  A3: {value: '297 x 420 мм', label: 'А3 (297 х 420 мм)'},
  A4: {value: '210 x 297 мм', label: 'А4 (210 х 297 мм)'},
  A5: {value: '148 x 210 мм', label: 'А5 (148 х 210 мм)'},
  A6: {value: '105 x 148 мм', label: 'А6 (105 х 148 мм)'},
  A7: {value: '74 x 105 мм', label: 'А7 (74 х 105 мм)'},
}

const types = {
  posters: ['A3', 'A4'].map(key => radios[key]),
  postcards: ['A5', 'A6', 'A7'].map(key => radios[key]),
  multipages: ['A4', 'A5', 'A6', 'A7'].map(key => radios[key]),
}

export const Format = ({value, type, onSubmit, onChange, onReset, ...props}) => {
  const {error} = U.validate.format.validate(value)

  return (
    <C.Form onSubmit={error ? null : onSubmit}>
      <C.Container>
        <C.Fieldset>
          <C.RadioGroup label="Выберите формат">
            {types[type].map((radio, i) => (
              <C.Radio
                name="format"
                key={radio.value}
                autoFocus={i === 0}
                label={radio.label}
                value={radio.value}
                checked={radio.value === (value && `${value[0]} x ${value[1]} мм`)}
                onChange={onChange}
                />
            ))}
          </C.RadioGroup>
        </C.Fieldset>
      </C.Container>

      <C.Footer>
        <C.Caption __html={props.format || ''}/>
        <div>
          <C.Reset onClick={onReset}>Заново</C.Reset>
          <C.Button disabled={Boolean(error)}>Далее</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  type: $$.selectors.calculator.type(state),
  value: $$.selectors.calculator.data(state).format,
})

export const mapDispatchToProps = dispatch => ({
  onChange: value => dispatch($$.actions.calculator.setData('format', [
    Number(value.match(/(?:(?!x).)*/i)[0]) || null,
    Number(value.match(/[^x]*$/)[0].replace('мм', '').trim()) || null,
  ])),
  onReset: () => dispatch($$.actions.calculator.reset()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    value: T.arrayOf(T.number).isRequired,
    type: T.oneOf(['posters', 'postcards', 'multipages']).isRequired,
    onChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
  }),
)

export default enhance(Format)
