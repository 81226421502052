import React from 'react';
import T from 'prop-types';
import styles from './Label.module.css';
import cx from 'classnames';

export const Label = ({children, faded, colorsLabel, className, ...props}) => (
  <p className={cx(styles.root, className, {[styles.faded]: faded, [styles.colorsLabel]: colorsLabel})} {...props}>{children}</p>
);

Label.propTypes = {
  children: T.oneOfType([T.string.isRequired, T.node.isRequired]),
  colorsLabel: T.any,
  faded: T.any,
};

export default Label;
