import React from 'react'
import cx from 'classnames'
import T from 'prop-types'
import styles from './Select.module.css'

export const Select = ({options, selected, isOpen, onChange, onClose, onOpen, inTabs}) => (
  <div className={cx(styles.root, {[styles.open]: isOpen, [styles.inTabs]: inTabs})} onMouseLeave={onClose}>
    <button type="button" className={styles.trigger} onClick={isOpen ? onClose : onOpen}>
      {selected}
      <span className={styles.arrow}>
        <svg viewBox="0 0 13 8">
          <path d="M6.918 8L13 0H.835z" fillRule="evenodd"/>
        </svg>
      </span>
    </button>

    <section className={styles.modal}>
      {options.map((option, index) => (
        <button
          key={index}
          type="button"
          className={cx(styles.option, {[styles.active]: option === selected})}
          onClick={() => onChange(option)}
          >
          {option}
        </button>
      ))}
    </section>
  </div>
)

Select.propTypes = {
  options: T.arrayOf(T.string.isRequired).isRequired,
  selected: T.string.isRequired,
  isOpen: T.bool.isRequired,
  onClose: T.func.isRequired,
  onChange: T.func.isRequired,
  onOpen: T.func.isRequired,
}

export default Select
