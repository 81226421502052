import React, {useState} from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, setPropTypes} from 'recompose'
import {uniq} from 'ramda'
import Img from 'gatsby-image/withIEPolyfill';
import * as C from '../../../_'
import * as Q from 'queries'
import * as U from 'utils'
import * as $$ from 'ducks'

export const Paper = ({selected, onSubmit, onChange, onReset, ...props}) => {
  const [papers, customImage] = Q.usePapers()
  const [activeIndex, setActiveIndex] = useState(0)
  const [isOpen, onOpenChange] = useState(false)
  const options = uniq(papers.map(paper => paper.category).filter(category => category !== 'white'))
  const [selectedCategory, onCategoryChange] = useState(options[0])
  const {error} = activeIndex !== 2 ? U.validate.paper.validate(selected) : {}

  let data = papers.reduce((acc, paper) => paper.title ? ({
    ...acc,
    [paper.title]: {
      ...paper,
      gsm: uniq([...(acc[paper.title] ? acc[paper.title].gsm : []), paper.gsm]),
    },
  }) : acc, {})
  data = Object.keys(data).map(key => data[key])

  const blocks = [
    <C.RadioSlider
      required
      name="paper"
      value={selected}
      key="white-paper"
      items={data.filter(({category}) => category === 'white')}
      onChange={onChange}
    />,
    <>
      <C.Select
        isOpen={isOpen}
        options={options}
        selected={selectedCategory}
        onOpen={() => onOpenChange(true)}
        onClose={() => onOpenChange(false)}
        onChange={val => {onCategoryChange(val); onChange([null, null])}}
        inTabs={true}
      />
      <C.RadioSlider
        required
        name="paper"
        value={selected}
        key="colored-paper"
        items={data.filter(({category}) => category === selectedCategory && category !== 'white')}
        onChange={onChange}
      />
    </>,
    <Img
      objectFit="cover"
      objectPosition="50% 50%"
      alt="Custom Paper"
      key="custom-paper"
      fluid={customImage.childImageSharp.fluid}
      style={{margin: '14px auto 0', display: 'block', maxWidth: 475}}
    />,
  ]

  return (
    <C.Form onSubmit={error ? null : onSubmit}>
      <C.Container>
        <C.Label>Выберите бумагу</C.Label>
        <C.Tabs blocks={blocks} tabs={['Белые', 'Цветные', 'Я принесу свою бумагу']} onTabChange={v => {setActiveIndex(v); onChange([null, null])}}/>
      </C.Container>

      <C.Footer style={{marginTop: 0}}>
        <C.Caption __html={props.paper || ''}/>
        <div>
          <C.Reset onClick={onReset}>Заново</C.Reset>
          <C.Button disabled={Boolean(error)}>Далее</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  selected: $$.selectors.calculator.data(state).paper,
})

export const mapDispatchToProps = dispatch => ({
  onChange: value => dispatch($$.actions.calculator.setData('paper', value)),
  onReset: () => dispatch($$.actions.calculator.reset()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    selected: T.array.isRequired,
    onChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
  }),
)

export default enhance(Paper)
