import React from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, setPropTypes} from 'recompose'
import * as C from '../../../_'
import * as $$ from 'ducks'

export const Extra = ({projectComment, onCommentChange, isColorProofs, onColorProofsChange, onSubmit, onReset, ...props}) => (
  <C.Form onSubmit={onSubmit}>
    <C.Container>
      <C.TextArea
        autoFocus
        value={projectComment}
        label="Комментарий к проекту"
        placeholder="Если у вас есть какие-то коментарии по выполнению заказа, оставьте их здесь"
        onChange={e => onCommentChange(e.target.value)}
      />
      <C.Checkbox
        checked={isColorProofs}
        label="Мне нужна цветопроба"
        onChange={e => onColorProofsChange(e.target.checked)}
      />
    </C.Container>

    <C.Footer>
      <C.Caption __html={props.orderExtra || ''}/>
      <div>
        <C.Reset onClick={onReset}>Заново</C.Reset>
        <C.Button>Рассчитать стоимость</C.Button>
      </div>
    </C.Footer>
  </C.Form>
)

export const mapStateToProps = state => ({
  isColorProofs: $$.selectors.calculator.data(state).isColorProofs,
  projectComment: $$.selectors.calculator.data(state).projectComment,
})

export const mapDispatchToProps = dispatch => ({
  onColorProofsChange: value => dispatch($$.actions.calculator.setData('isColorProofs', value)),
  onCommentChange: value => dispatch($$.actions.calculator.setData('projectComment', value)),
  onReset: () => dispatch($$.actions.calculator.reset()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    projectComment: T.string.isRequired,
    onCommentChange: T.func.isRequired,
    isColorProofs: T.bool.isRequired,
    onColorProofsChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
  }),
)

export default enhance(Extra)
