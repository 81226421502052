import React from 'react'
import T from 'prop-types'
import Img from 'gatsby-image'
import {connect} from 'react-redux'
import * as $$ from 'ducks'
import styles from './Initial.module.css'
import * as C from '../../_'
import * as Q from 'queries'
import data from './data'

export const Initial = ({onChange, ...props}) => {
  const {allFile} = Q.useImages()

  return (
    <C.Root>
      <section className={styles.container}>
        {data.options.map(([type, image, title]) => (
          <article key={type} className={styles.type} onClick={() => onChange(type)}>
            <Img className={styles.image} fluid={allFile.edges.find(({node: {relativePath}}) => relativePath === image).node.childImageSharp.fluid}/>
            <p className={styles.title}>{title}</p>
          </article>
        ))}
      </section>

      <C.Footer>
        <C.Caption __html={props.initial || ''}/>
      </C.Footer>
    </C.Root>
  )
}

Initial.propTypes = {
  initial: T.string,
  onChange: T.func.isRequired,
}

export const mapDispatchToProps = dispatch => ({
  onChange: type => {
    dispatch($$.actions.calculator.setType(type))
    dispatch($$.actions.calculator.setStage('parameters'))
  },
})

export default connect(null, mapDispatchToProps)(Initial)
