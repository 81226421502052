import React from "react"
import T from "prop-types"
import { connect } from "react-redux"
import { selectors } from "ducks"
import * as Screens from "./screens"
import SEO from "Layout/SEO"

export const stages = {
  initial: Screens.Initial,
  parameters: Screens.Parameters,
  checkout: Screens.Checkout,
}

export const CalculatorPageTemplate = ({ isFinished, stage, ...props }) => {
  const Component = isFinished ? Screens.Success : stages[stage]
  return <Component {...props.data.markdownRemark.frontmatter} />
}

export const CalculatorPage = props => (
  <>
    <SEO {...props.data.markdownRemark.frontmatter.seo} {...props} />
    {/* <p className="error-text">
      <span>
        К сожалению, калькулятор сейчас работает некорректно. Пока мы исправляем
        ошибки, пишите на&nbsp;
        <a href="mailto:inbox@eshprint.com">inbox@eshprint.com</a> и мы
        рассчитаем стоимость печати вручную.
      </span>
    </p> */}
    <CalculatorPageTemplate {...props} />
  </>
)

export const mapStateToProps = state => ({
  stage: selectors.calculator.stage(state),
  isFinished: selectors.calculator.isFinished(state),
})

CalculatorPage.propTypes = {
  stage: T.oneOf(["initial", "parameters", "checkout"]).isRequired,
  isFinished: T.bool.isRequired,
  data: T.shape({
    markdownRemark: T.shape({
      frontmatter: T.shape({
        seo: T.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default connect(mapStateToProps)(CalculatorPage)
