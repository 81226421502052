import React from 'react';
import T from 'prop-types';
import {compose, withHandlers, setPropTypes} from 'recompose';
import styles from './Form.module.css';

export const Form = ({children, onSubmit}) => (
  <form className={styles.root} onSubmit={onSubmit}>
    {children}
  </form>
);

export const enhance = compose(
  withHandlers({
    onSubmit: props => ev => {
      ev.preventDefault();
      if (props.onSubmit) props.onSubmit();
    },
  }),
  setPropTypes({
    children: T.node,
    onSubmit: T.func.isRequired,
  }),
);

export default enhance(Form);
