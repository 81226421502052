import React from 'react'
import cx from 'classnames'
import T from 'prop-types'
import Markdown from 'react-markdown'
import styles from './Caption.module.css'

export const Caption = ({className, __html}) => (
  <Markdown className={cx(styles.root, className)} source={__html}/>
)

Caption.propTypes = {
  className: T.string,
  __html: T.string.isRequired,
}

export default Caption
