import React from 'react'
import cx from 'classnames';
import T from 'prop-types'
import isDarkColor from 'is-dark-color';
import styles from './ColoredCheckboxes.module.css'

export const ColoredCheckboxes = ({options, faded, name, selected, onChange}) => (
  <section className={cx(styles.root, {[styles.faded]: faded})}>
    {options.map(option => (
      <label key={option.value} className={styles.option} style={{backgroundColor: option.color}}>
        <input
          name={name}
          type="checkbox"
          value={option.value}
          className={styles.checkbox}
          checked={selected.includes(option.value)}
          onChange={onChange}
          />
        <span className={cx(styles.label, {[styles.dark]: isDarkColor(option.color)})}>
          <span className={styles.arrow}>
            <svg viewBox="0 0 15 12">
              <path d="M2 4.5L6.304 10 13 2" strokeWidth="4" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </span>
          <span className={styles.circle} style={{backgroundColor: option.color}}></span>
          {option.label}
        </span>
      </label>
    ))}
  </section>
)

ColoredCheckboxes.propTypes = {
  name: T.string.isRequired,
  faded: T.bool,
  options: T.arrayOf(T.shape({
    value: T.string.isRequired,
    label: T.string.isRequired,
    color: T.string.isRequired,
  }).isRequired).isRequired,
  selected: T.arrayOf(T.string.isRequired).isRequired,
  onChange: T.func.isRequired,
}

export default ColoredCheckboxes
