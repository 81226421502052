import React from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, setPropTypes} from 'recompose'
import * as C from '../../../_'
import * as U from 'utils'
import * as $$ from 'ducks'

export const mask = value =>
  [...([...Array(Math.min(4, value.replace(/\sкопий/, '').split('').map(v => Number(v)).filter(v => Number.isInteger(v)).length)).keys()].map(() => /\d/)), ' ', 'к', 'о', 'п', 'и', 'й']

const style = {maxWidth: 280}

export const Edition = ({value, onSubmit, onChange, onReset, ...props}) => {
  const {error} = U.validate.edition.validate(value)

  return (
    <C.Form onSubmit={error ? null : onSubmit}>
      <C.Container>
        <C.Fieldset>
          <C.TextInput
            required
            autoFocus
            name="format"
            placeholderChar=" "
            showMask
            mask={mask}
            style={style}
            label="Введите тираж"
            value={value ? `${value} копий` : ''}
            error={error ? `Минимальный тираж 25 копий` : null}
            onChange={onChange}
            />
        </C.Fieldset>
      </C.Container>

      <C.Footer>
        <C.Caption __html={props.edition || ''}/>
        <div>
          <C.Reset onClick={onReset}>Заново</C.Reset>
          <C.Button disabled={Boolean(error)}>Далее</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  value: $$.selectors.calculator.data(state).edition,
})

export const mapDispatchToProps = dispatch => ({
  onChange: value =>
    dispatch($$.actions.calculator.setData('edition', Number(value.replace(/\sкопий/, '')))),
  onReset: () => dispatch($$.actions.calculator.reset()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    value: T.number,
    onChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
  }),
)

export default enhance(Edition)
