import React from 'react'
import T from 'prop-types'
import {flatten, uniq} from 'ramda'
import {connect} from 'react-redux'
import {compose, setPropTypes} from 'recompose'
import * as C from '../../../../_'
import * as Q from 'queries'
import * as U from 'utils'
import * as $$ from 'ducks'
import File from './File'
import styles from './Files.module.css'

export const Files = ({items, folderName, colors, onSubmit, onChange, onReset, onUpload, ...props}) => {
  const isValid = U.validate.files.validate(items, colors)
  const data = Q.useColors()
  const isSingleColor = uniq(flatten(colors)).length === 1

  return (
    <C.Form onSubmit={isValid ? onSubmit : null} className={styles.form}>
      <C.Label className={styles.label}>Вставьте ссылки или загрузите файлы</C.Label>
      <section className={styles.wrapper}>
        <C.Container>
          <C.Label className={styles.labelSmall}>Сторона 1</C.Label>
            {data.filter(item => colors[0].includes(item.value)).map((color, i) => (
              <File
                color={color}
                key={color.value}
                autoFocus={i === 0}
                id={`0-${color.value}`}
                folderName={folderName}
                value={items[0][color.value] || ''}
                onChange={val => onChange([{...items[0], [color.value]: val}, items[1], items[2]])}
              />
            ))}
        </C.Container>

        {colors[1].length === 0 ? null : (
          <C.Container>
            <C.Label className={styles.labelSmall}>Сторона 2</C.Label>
            {data.filter(item => colors[1].includes(item.value)).map((color, i) => (
              <File
                color={color}
                key={color.value}
                id={`1-${color.value}`}
                folderName={folderName}
                value={items[1][color.value] || ''}
                onChange={val => onChange([items[0], {...items[1], [color.value]: val}, items[2]])}
              />
            ))}
          </C.Container>
        )}

        {isSingleColor ? null : (
          <C.Container>
            <C.Label className={styles.labelSmall}>Полноцветный файл</C.Label>
            <File
              id="all-colors"
              value={items[2] || ''}
              caption="Все цвета вместе"
              folderName={folderName}
              onChange={val => onChange([items[0], items[1], val])}
            />
          </C.Container>
        )}
      </section>

      <C.Footer>
        <C.Caption __html={props.files || ''}/>
        <div>
          <C.Reset onClick={onReset}>К расчётам</C.Reset>
          <C.Button disabled={!isValid}>Далее</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  colors: $$.selectors.calculator.data(state).colors,
  items: $$.selectors.calculator.data(state).files,
  folderName: $$.selectors.calculator.folderName(state),
})

export const mapDispatchToProps = dispatch => ({
  onChange: files => dispatch($$.actions.calculator.setData('files', files)),
  onUpload: (file, color, side) => dispatch($$.actions.calculator.uploadFile(file, color, side)),
  onReset: () => dispatch($$.actions.calculator.resetCheckout()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    folderName: T.string.isRequired,
    files: T.string,
    items: T.array.isRequired,
    colors: T.array.isRequired,
    onChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
    onUpload: T.func.isRequired,
  }),
)

export default enhance(Files)
