import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import {compose, withState, lifecycle, withHandlers, setPropTypes} from 'recompose'
import styles from './Navigation.module.css'

export const titles = {
  format: 'формат',
  edition: 'тираж',
  pages: 'количество полос',
  paper: 'бумага',
  colors: 'цвета',
  brace: 'скрепление',
  extra: 'дополнительно',
  title: 'название',
  files: 'файлы',
  delivery: 'доставка',
  contacts: 'контакты',
}

export const Navigation = ({elements, width, active, activeWidth, onChange}) => {
  const activeIndex = elements.indexOf(active)

  return (
    <nav className={styles.root}>
      <button type="button" className={styles.back} onClick={() => onChange(elements[activeIndex - 1])}>Назад</button>
      <div className={styles.step}>{`Шаг ${activeIndex + 1} из ${elements.length}`}</div>

      <span className={styles.line} style={{width: activeWidth}}/>
      <span className={styles.preline} style={{width}}/>
      <div className={styles.wrapper}>
        {elements.map((key, i) => (
          <button
            key={key}
            type="button"
            className={cx(styles.button, {[styles.active]: i <= activeIndex, [styles.selected]: i === activeIndex})}
            onClick={i <= activeIndex ? () => onChange(key) : null}
            >
            {titles[key]}
          </button>
        ))}
      </div>
    </nav>
  )
}

export const enhance = compose(
  withState('activeWidth', 'setActiveWidth', 0),
  withState('width', 'setWidth', 0),
  withHandlers({
    // @TODO
    // Convert to class and refs, so several instances
    // can be used on screen at once!
    onSetActiveWidth: props => () => {
      const $button = document.querySelector(`.${styles.selected}`)
      const $nav = document.querySelector(`.${styles.root}`)
      const $wrapper = document.querySelector(`.${styles.wrapper}`)
      if (!$button && !$wrapper && !$nav) return
      const {left, width} = $button.getBoundingClientRect()
      props.setWidth($wrapper.scrollWidth)
      props.setActiveWidth(left + width - $wrapper.getBoundingClientRect().left)
      $nav.scrollLeft = left - $nav.getBoundingClientRect().left
    },
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.onSetActiveWidth, {passive: true})
      this.props.onSetActiveWidth()
    },
    componentDidUpdate(prevProps) {
      if (prevProps.active !== this.props.active) {
        this.props.onSetActiveWidth()
      }
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.onSetActiveWidth, {passive: true})
    },
  }),
  setPropTypes({
    elements: T.arrayOf(T.string.isRequired).isRequired,
    active: T.string.isRequired,
    activeWidth: T.number.isRequired,
    width: T.number.isRequired,
    onChange: T.func.isRequired,
  }),
)

export default enhance(Navigation)
