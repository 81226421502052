import React from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, withHandlers, setPropTypes} from 'recompose'
import * as $$ from 'ducks'
import * as C from '../../_'
import * as Steps from './Steps'

const data = {
  steps: [
    ['title', Steps.Title],
    ['files', Steps.Files],
    ['delivery', Steps.Delivery],
    ['contacts', Steps.Contacts],
    ['extra', Steps.Extra],
  ]
}

export const Checkout = ({step, onSubmit, onBack, ...props}) => {
  const Step = (data.steps.find(([key]) => key === step) || [])[1]

  return (
    <C.Root>
      <C.Navigation
        active={step}
        elements={data.steps.map(([key]) => key)}
        onChange={key => key ? onSubmit(key) : onBack()}
        />
      <Step {...props} onSubmit={onSubmit}/>
    </C.Root>
  )
}

export const mapStateToProps = state => ({
  step: $$.selectors.calculator.step(state),
})

export const mapDispatchToProps = ({
  onSubmit: $$.actions.calculator.setStep,
  onFinish: $$.actions.calculator.submit,
  onBack: $$.actions.calculator.resetCheckout,
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onSubmit: props => (step, ...args) => {
      if (step) {
        props.onSubmit(step)
      } else {
        if (props.step === 'extra') {
          props.onFinish(...args)
        } else {
          const steps = data.steps.map(([key]) => key)
          const currentIndex = steps.indexOf(props.step)
          props.onSubmit(steps[currentIndex + 1])
        }
      }
    },
  }),
  setPropTypes({
    step: T.oneOf(['title', 'files', 'delivery', 'contacts', 'extra']).isRequired,
  }),
)

export default enhance(Checkout)
