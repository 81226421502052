import React from "react"
import T from "prop-types"
import { connect } from "react-redux"
import { compose, setPropTypes } from "recompose"
import * as C from "../../../_"
import * as U from "utils"
import * as $$ from "ducks"

const radios = [
  {
    value: "pickup",
    label: "Самовывоз: Москва, 4-й Сыромятнический пер., 1/8 строение 6",
  },
  {
    value: "courier",
    label: "Курьерская доставка",
  },
]

export const Delivery = ({ value, onSubmit, onChange, onReset, ...props }) => {
  const { error } = U.validate.delivery.validate(value)

  return (
    <C.Form onSubmit={error ? null : onSubmit}>
      <C.Container>
        <C.Fieldset>
          <C.RadioGroup label="Выбрите, как вы хотите забрать свой заказ">
            {radios.map(radio => (
              <C.Radio
                name="delivery"
                key={radio.value}
                label={radio.label}
                value={radio.value}
                checked={radio.value === value}
                onChange={onChange}
              />
            ))}
          </C.RadioGroup>
        </C.Fieldset>
      </C.Container>

      <C.Footer>
        <C.Caption __html={props.delivery || ""} />
        <div>
          <C.Reset onClick={onReset}>К расчётам</C.Reset>
          <C.Button disabled={Boolean(error)}>Далее</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  value: $$.selectors.calculator.data(state).delivery,
})

export const mapDispatchToProps = dispatch => ({
  onChange: value => dispatch($$.actions.calculator.setData("delivery", value)),
  onReset: () => dispatch($$.actions.calculator.resetCheckout()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    value: T.string,
    onChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
  })
)

export default enhance(Delivery)
