import React from 'react'
import cx from 'classnames'
import T from 'prop-types'
import Modal from "UI/Modal"
import styles from './Select.module.css'

export class Select extends React.Component {
  static propTypes = {
    value: T.number,
    options: T.array.isRequired,
    onChange: T.func.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.$button = React.createRef()
  }

  state = ({
    isOpen: false,
    left: 0,
    top: 0,
    width: 0,
  })

  componentDidMount() {
    this.setButtonPositionAndWidth()
    window.addEventListener('scroll', this.setButtonPositionAndWidth, {passive: true})
    window.addEventListener('resize', this.setButtonPositionAndWidth, {passive: true})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen || this.props.scrollX !== prevProps.scrollX) {
      this.setButtonPositionAndWidth()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setButtonPositionAndWidth, {passive: true})
    window.removeEventListener('resize', this.setButtonPositionAndWidth, {passive: true})
  }

  setButtonPositionAndWidth = () => {
    if (this.$button.current && this.state.isOpen) {
      const doc = document.documentElement
      const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      const {top, left, width} = this.$button.current.getBoundingClientRect()
      this.setState({top: top + scrollTop, left: left - 2, width: width + 4}) // Borders :(

      const $container = document.querySelector('#radio-slider')

      if (left < $container.getBoundingClientRect().left) {
        this.setState({isOpen: false})
      }
    }
  }

  onOpen = () => this.setState({isOpen: true})
  onClose = () => this.setState({isOpen: false})

  render() {
    const {options, onChange} = this.props
    const {isOpen, width, top, left} = this.state
    const selected = this.props.value

    return (
      <div data-top={top} className={cx(styles.root, {[styles.open]: isOpen})} onMouseLeave={() => this.setState({isOpen: false})}>
        <button ref={this.$button} type="button" className={styles.trigger} onClick={isOpen ? this.onClose : this.onOpen}>
          {selected}
          <span className={styles.arrow}>
            <svg viewBox="0 0 13 8">
              <path d="M6.918 8L13 0H.835z" fillRule="evenodd"/>
            </svg>
          </span>
        </button>

        {isOpen ? (
          <Modal>
            <section data-modal="select" className={styles.modal} style={{width, top, left}}>
              {options.map((option, index) => (
                <button
                  key={index}
                  type="button"
                  className={cx(styles.option, {[styles.active]: option === selected})}
                  onClick={() => onChange(option)}
                  >
                  {option}
                </button>
              ))}
            </section>
          </Modal>
        ) : null}
      </div>
    )
  }
}

export default Select
