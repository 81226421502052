import React from 'react'
import T from 'prop-types'
import {connect} from 'react-redux'
import {compose, setPropTypes} from 'recompose'
import emailMask from 'text-mask-addons/dist/emailMask'
import * as C from '../../../_'
import * as U from 'utils'
import * as $$ from 'ducks'

export const Contacts = ({value, delivery, onSubmit, onChange, onReset, ...props}) => {
  const {error} = U.validate.contacts.all[delivery].validate(value)

  return (
    <C.Form onSubmit={error ? null : onSubmit}>
      <C.Container>
        <C.Grid>
          <C.TextInput
            required
            autoFocus
            name="name"
            value={value.name}
            label="Меня зовут"
            placeholder="Имя Фамилия"
            error={U.validate.contacts.name.validate(value.name).error ? 'Длина имени должна быть больше 3 символов' : null}
            onChange={val => onChange(['name', val], value)}
            />

          <C.TextInput
            guide
            required
            name="tel"
            label="Телефон"
            value={value.tel}
            placeholder="+7 (000) 000-00-00"
            mask={['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
            error={U.validate.contacts.tel.validate(value.tel).error ? 'Введите валидный номер телефона' : null}
            onChange={val => onChange(['tel', val], value)}
            />

          <C.TextInput
            guide
            required
            name="email"
            label="Email"
            value={value.email}
            placeholder="email@email.com"
            mask={emailMask}
            error={U.validate.contacts.email.validate(value.email).error ? 'Введите валидный почтовый адрес' : null}
            onChange={val => onChange(['email', val], value)}
            />

          {delivery === 'courier' ? (
            <>
              <C.TextInput
                required
                name="city"
                label="Город"
                value={value.city}
                placeholder="Город"
                onChange={val => onChange(['city', val], value)}
                />

              <C.TextInput
                required
                name="address"
                label="Адрес"
                wideWidth={true}
                value={value.address}
                placeholder="Улица/корпус, строение"
                onChange={val => onChange(['address', val], value)}
                />

              <C.TextInput
                required
                name="flat"
                value={value.flat}
                label="Подъезд, этаж и квартира"
                placeholder="Подъезд, этаж и квартира"
                onChange={val => onChange(['flat', val], value)}
                />

              <C.TextInput
                required
                name="index"
                label="Индекс"
                value={value.index}
                placeholder="Индекс"
                onChange={val => onChange(['index', val], value)}
                />
            </>
          ) : null}
        </C.Grid>
      </C.Container>

      <C.Footer>
        <C.Caption __html={props.contacts || ''}/>
        <div>
          <C.Reset onClick={onReset}>К расчётам</C.Reset>
          <C.Button disabled={Boolean(error)}>Далее</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  value: $$.selectors.calculator.data(state).contacts,
  delivery: $$.selectors.calculator.data(state).delivery,
})

export const mapDispatchToProps = dispatch => ({
  onChange: ([key, val], value) => dispatch($$.actions.calculator.setData('contacts', {...value, [key]: val})),
  onReset: () => dispatch($$.actions.calculator.resetCheckout()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    value: T.object.isRequired,
    delivery: T.string.isRequired,
    onChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
  }),
)

export default enhance(Contacts)
