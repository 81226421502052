import React from 'react'
import T from 'prop-types'
import styles from './Reset.module.css'

export const Reset = ({children, onClick}) => (
  <div className={styles.root}>
    <button type="reset" onClick={onClick}>{children}</button>
  </div>
)

Reset.propTypes = {
  children: T.any,
  onClick: T.func.isRequired,
}

export default Reset
