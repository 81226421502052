import React from 'react'
import T from 'prop-types'
import Img from 'gatsby-image'
import {connect} from 'react-redux'
import {compose, setPropTypes} from 'recompose'
import * as C from '../../../../_'
import * as Q from 'queries'
import * as U from 'utils'
import * as $$ from 'ducks'
import styles from './Brace.module.css'

const data = [
  ['brace-skobka-nakidku.png', 'Обычная скоба внакидку'],
  ['brace-fig-skobka.png', 'Архивная скоба внакидку'],
  ['brace-v-tachku.png', 'Обычная скоба втачку'],
]

export const Brace = ({value, onSubmit, onChange, onReset, ...props}) => {
  const {allFile} = Q.useImages()
  const {error} = U.validate.brace.validate(value)

  return (
    <C.Form onSubmit={error ? null : onSubmit}>
      <C.Container>
        <C.Label>Выберите тип скрепления</C.Label>
        <section className={styles.items}>
          {data.map(([image, label], i) => (
            <article key={label} className={styles.type} onClick={() => onChange(label)}>
              <Img className={styles.image} fluid={allFile.edges.find(({node: {relativePath}}) => relativePath === image).node.childImageSharp.fluid}/>
              <C.Radio
                autoFocus={i === 0}
                checked={value === label}
                name="brace"
                label={label}
                value={label}
                onClick={() => onChange(label)}
              />
            </article>
          ))}
        </section>
      </C.Container>

      <C.Footer>
        <C.Caption __html={props.brace || ''}/>
        <div>
          <C.Reset onClick={onReset}>Заново</C.Reset>
          <C.Button disabled={Boolean(error)}>Далее</C.Button>
        </div>
      </C.Footer>
    </C.Form>
  )
}

export const mapStateToProps = state => ({
  value: $$.selectors.calculator.data(state).brace,
})

export const mapDispatchToProps = dispatch => ({
  onChange: value => dispatch($$.actions.calculator.setData('brace', value)),
  onReset: () => dispatch($$.actions.calculator.reset()),
})

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  setPropTypes({
    value: T.string,
    onSubmit: T.func.isRequired,
    onReset: T.func.isRequired,
  }),
)

export default enhance(Brace)
